import React from 'react';
// import PaymentQRCode from './PaymentQRCode';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>QR Code Payments</h1>
      {/* <PaymentQRCode /> */}
    </div>
  );
}

export default App;
